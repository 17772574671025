.div_show_data_product .Comp_Input .inf_input{
    width: 86px;
    text-align: center;
}

.div_show_data_product .Comp_Input .size{
    width: 100px;
    text-align: center;
}
.div_show_data_product .Comp_Input .qtd{
    width: 40px;
    text-align: center;
}
.div_show_data_product .Comp_Input .brands{
    width: 200px;
}