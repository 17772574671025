.Comp_Select{
    width: max-content;
    position: relative;
    margin-top: 8px;
    color: #424242;
    text-align: left;
}
.Comp_Select_flex_grow {
    flex-grow: 1;
}
.Comp_Select select{    
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.Comp_Select .name{
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0px 10px;
    background: #ffffff;
}
.Comp_Select .css-13cymwt-control{
    border: 2px solid #ebebeb !important;
    font-family: 'inter' !important;
}
.Comp_Select .css-t3ipsp-control{
    border: 2px solid #ebebeb !important;
    box-shadow: none !important;
    border-width: 2px !important;
}
.Comp_Select .css-t3ipsp-control:hover{
    border-color: #ebebeb !important;
    box-shadow: none !important;
    outline: none !important;
    border-width: 2px !important;
}
.Comp_Select .css-b62m3t-container{
    min-width: 220px !important;
}
.Comp_Select .css-1nmdiq5-mnu{
    max-height: 100px !important;
}