.div_return{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}
.div_return .icons{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}