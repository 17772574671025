.div_show_data_site .type_data{
    width: 220px;
}

.div_show_data_site .div_preview{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.div_show_data_site .banner{
    gap: 40px;
}
.div_show_data_site .div_preview .opt_banner{
    width: 40%;
    height: 250px;
    border-radius: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.div_show_data_site .div_preview .only_banner{
    width: 100%;
    height: 400px;
}

.div_show_data_site .brands{
    gap: 40px;
    flex-wrap: nowrap;
    overflow: hidden;
}
.div_show_data_site .div_preview .div_arrow{
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}
.div_show_data_site .div_preview .div_arrow .arrow_right{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: .5s;
}
.div_show_data_site .div_preview .div_list_data{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    overflow-x: hidden;
}
.div_show_data_site .div_preview .div_list_data .div_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div_show_data_site .banner .div_list_data .div_img {
    width: 160px;
    height: 100px;
}
.div_show_data_site .category .div_list_data .div_img {
    width: 170px;
    min-width: 170px;
    height: 150px;
    min-height: 150px;
    align-items: center;
    justify-content: space-around;
}
.div_show_data_site .div_preview .div_list_data .div_img .show_logo{
    width: auto;
    height: auto;
    max-height: 100px;
    display: flex;
}
.div_show_data_site .div_preview .div_list_data .div_img .title{
    font-weight: bold;
}
.div_show_data_site .faq{
    flex-direction: column;
    gap: 16px;
}
.div_show_data_site .faq .div_list_data{
    width: 100%;
    gap: 0;
    flex-direction: column; 
}
.div_show_data_site .faq .question{ 
    width: calc(100% - 20px);
    height: 40px;
    color: #ffffff;
    padding: 0px 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.div_show_data_site .faq .question .icons{ 
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: .5s;
}
.div_show_data_site .faq .question .icons_active{ 
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.div_show_data_site .faq .response{    
    max-height: 0;
    overflow-y: hidden;
    transition: max-height .5s ease-out;
    width: calc(100% - 22px);
}
.div_show_data_site .services_product{
    gap: 20px;
}
.div_show_data_site .services_product .div_list_data{
    gap: 40px;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product{
    width: 250px;
    min-width: 250px;
    height: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_img{
    width: 100%;
    height: 180px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .title{
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .subtitle{
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_price{

}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_price .bold{
    font-weight: bold;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_price .underline{
    text-decoration: line-through;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_price .discount_price{
    font-weight: bold;
    font-size: 20px;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .btn{
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_details{
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_purchase{
    color: #ffffff;
}
.div_show_data_site .services_product .div_list_data .div_service_or_product .btn .title_btn{

}
.div_show_data_site .services_product .div_list_data .div_service_or_product .div_purchase .icons{
    width: 18px;
    height: auto;
}