.PopUp .Schedule{
    width: 800px;
    margin-bottom: 40px;
}
.PopUp .Schedule .div_content{
    color: #2d3c53;
    flex-direction: column;
    gap: 16px;
}
.PopUp .Schedule .div_data .content{
    flex-direction: row;
    justify-content: flex-start;
    overflow: inherit;
}
.PopUp .Schedule .status{
    width: 170px;
}
.PopUp .Schedule .date{
    width: 120px;
}
.PopUp .Schedule .hour, .PopUp .Schedule .price{
    width: 80px;
}
.PopUp .Schedule .price{
    text-align: center;
}
.PopUp .Schedule .div_btn{
    width: 100%;
    border-radius: 6px;
}
.PopUp .Schedule .btn_save{
    width: 100%;
    padding: 8px 10px;
    background-color: #005FA1;
    border: none;
    cursor: pointer;
    color: #ededed;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Inter';
    border-radius: 6px;
    margin-top: 20px;

    justify-content: center;
}
.PopUp .Schedule .btn_save:hover{
    background-color: #0079CD;
}
.PopUp .Schedule .div_Comp_select .Comp_Select{
    width: 100%;
}
.PopUp .Schedule .div_Comp_select .Comp_Select .patient{
    flex-grow: 1;
    text-align: left;
}