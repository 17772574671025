.Comp_InputCheckbox{
    position: relative;
    margin-top: 8px;
    color: #424242;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.Comp_InputCheckbox input{
    width: 16px;
    height: 16px;
    border: 2px solid #ebebeb;
    outline: none;
    background-color: transparent;
}
.Comp_InputCheckbox input[type="checkbox"] {
    appearance: none; /* Remove o estilo padrão do checkbox */
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ebebeb; /* Define a borda inicial */
    position: relative;
}
.Comp_InputCheckbox input[type="checkbox"]:checked::before {
    content: '✔';
    position: absolute;
    top: 0;
    left: 3px;
    color: #000000; /* Cor do checkmark */
    font-size: 14px;
}
