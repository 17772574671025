.PopUp .recover_pass{
    width: 500px;
    margin: 0;
}
.PopUp .recover_pass .button_profile{
    width: -webkit-fill-available;
}
.PopUp .recover_pass .align_profile{
    text-align: left;
}
.PopUp .recover_pass .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .recover_pass .pass{
    width: 120px;
}
.PopUp .recover_pass .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .recover_pass .div_input{
    width: auto;
}
.PopUp .recover_pass .div_btn{
    width: 100%;
    border-radius: 6px;
}
.PopUp .recover_pass .msg_return{
    margin-top: 10px;
}
.PopUp .recover_pass .btn_save{
    width: 100%;
    padding: 8px 10px;
    background-color: #005FA1;
    border: none;
    cursor: pointer;
    color: #ededed;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Inter';
    border-radius: 6px;
    margin-top: 20px;

    justify-content: center;
}
.PopUp .recover_pass .btn_save:hover{
    background-color: #0079CD;
}
.PopUp .recover_pass .exit{
    width: calc(100% - 20px);
    padding: 6px 10px;
    font-size: 14px;
    background-color: #e51d4d;
}
.PopUp .recover_pass .exit:hover{
    background-color: #a70b2f;
}

@media only screen and (max-width: 570px) {
    .PopUp .recover_pass {
        width: 90%;
    }
}