html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #2d3c53;
    font-family: 'Poppins';
    background-color: #ffffff;
    overflow-x: hidden;
    letter-spacing: 0px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
    list-style: disc !important;
    padding-left: 18px;
}
ul li {
    margin-bottom: 20px;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.space_top{
    margin-top: 20px;
}
.space_div {
    flex-grow: 1;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.cursor_pointer{
    cursor: pointer;
}
.no_data{
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: #6c757d;
}
.list_opt{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
}
.show_img_{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.textarea{
    resize: none;
    min-height: 60px;
}
::placeholder{
    color: rgb(212, 212, 212);
}
.icon_delete{
    position: relative;
    top: 16px;
}


/* page */
.div_inf_page{
    width: calc(100% - 60px);
    height: auto;
    position: relative;
    padding: 30px 30px 70px 30px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
}
.div_inf_page .show_data_page {
    width: 100%;
    max-width: 1920px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 0px;
}
.div_inf_page .show_data_page:nth-child(n + 3) {
    margin-top: 20px;
}
.div_inf_page .show_data_page .div_opt_data{
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2d3c53;
    color: #ffffff;
    padding: 12px 16px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.div_inf_page .show_data_page .div_opt_data div:nth-child(2){
    cursor: pointer;
}
.div_inf_page .show_data_page .show_content_data{
    width: calc(100% - 32px);
    padding: 0px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}
.div_inf_page .show_data_page .show_content_data .div_delete{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}
.div_inf_page .show_data_page .show_content_data .show_color{
    border: 2px solid #ebebeb;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    flex-grow: 1;
}
.div_inf_page .show_data_page .show_content_data .show_color .select_color{
    width: 210px;
}
.div_inf_page .show_data_page .show_content_data .show_color .div_color{
    width: auto;
    height: 36px;
    padding: 0px 16px;
    
    border: 2px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.div_inf_page .show_data_page .show_content_data .show_color .div_show_color{
    color: #000000;
    font-weight: bold;
}
.div_inf_page .show_data_page .show_content_data .div_show_data_select {
    display: flex;
    align-items: center;
    gap: 8px;
}
.div_inf_page .show_data_page .show_content_data .div_show_data_select .opt_color{
    width: 16px;
    height: 16px;
}
.div_inf_page .show_data_page .show_content_data .div_show_data_select .opt_thumbnail{
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.div_inf_page .show_data_page .show_content_data table{
    width: 100%;
    padding: 0;
}
.div_inf_page .show_data_page .show_content_data table tr th{
    padding: 6px;
    background: #2d3c53;
    color: #ffffff;
    font-weight: normal;
}
.div_inf_page .show_data_page .show_content_data table tr th:nth-child(1){
    border-top-left-radius: 8px;
}
.div_inf_page .show_data_page .show_content_data table tr th:nth-last-child(-n + 1){
    border-top-right-radius: 8px;
}
.div_inf_page .show_data_page .show_content_data table tr td{
    padding: 6px;
    color: #2d3c53;
    border-bottom: 2px solid #2d3c53;
}
.div_inf_page .show_data_page .show_content_data table tr:nth-last-child(-n + 1){
    border-bottom: none;
}
.div_inf_page .show_data_page .div_border{
    width: calc(100% - 68px);
    border: 2px solid #ebebeb;
    margin: 0px 16px;
    border-radius: 8px;
    position: relative;
}
.div_inf_page .show_data_page .div_border_top{
    border-bottom: none;
}
.div_inf_page .show_data_page .div_border_top:nth-child(n + 2){
    padding-bottom: 20px;
    border-bottom: 2px solid #ebebeb;
}
.div_inf_page .show_data_page .div_border .name_checkbox{
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0px 10px;
    background: #ffffff;
}
.div_inf_page .show_data_page .show_content_data:nth-last-child(-n + 1){
    padding: 0px 16px 26px;
}
.div_inf_page .show_data_page .div_border{
    padding: 16px !important;
}
.div_inf_page .show_data_page .div_border:nth-last-child(-n + 1){
    margin: 0px 16px 26px;
}
/* end* /


/* list pages */
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}
.list_pages .numb_page{
    background-color: #111827;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.list_pages .numb_page:hover{
    background-color: #4a566e;
}
.list_pages .page_active {
    background-color: #a9b9cb;
}
/* end */


/* table */
.list_data_table{
    width: 100%;
}
.list_data_table .new_data{
    width: 100%;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.list_data_table .new_data .btn{
    padding: 6px 16px;
    background: #2d3c53;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
}
.list_data_table table{
    width: 100%;
    height: auto;
    background: #ffffff;
}
.list_data_table table tr th, .list_data_table table tr td{
    padding: 10px;
    border: 1px solid #4a566e;
    position: relative;
}
.list_data_table table tr th{
    border-right: none;
    background: #2d3c53;
    color: #ffffff;
}
.list_data_table table tr th:nth-last-child(-n + 1){
    border-right: 1px solid #4a566e;
}
.list_data_table table tr td{
    border-top: none;
    border-right: none;
}
.list_data_table table tr td:nth-last-child(-n + 1){
    border-right: 1px solid #4a566e;
}
.list_data_table table tr td input{
    background: transparent;
    border: none;
    border-bottom: 1px solid #4a566e;
    border-radius: 0;
    padding: 6px 0px;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #808FA3;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #ffffff;
}
/* end */


/* input */
input, select, textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
input[type="file"] {
    display: none;
}
select{
    padding: 8px;
}
/* end */


/* input file */
.new_file_add{
    background-color: #0089d0;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
}
.new_file_add:hover, .new_file_add_active{
    background-color: #20489e;
}
.div_add_img{
    display: flex !important;
    align-items: center !important;
    gap: 3px !important;
}
.div_add_img .delete_img_add{
    position: relative;
    margin-left: -10px;
    background-color: #e6272c;
    padding: 7px;
    color: #FFFFFF;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.new_file_add label{
    cursor: pointer;
}
.new_file_add input[type="file"]{
    display: none;
}
/* end */


/* div_site */
.div_site{
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 999;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
}
.div_site .icons {
    width: 18px;
    height: auto;
}
/* end */

/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* popup */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
    color: #ffffff;
}
.PopUpCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
    border: 1px solid #808FA3;
    margin-bottom: 40px;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
    color: #0079CD;
}
.PopUp .div_data .div_data_input{
    width: 100%;
    text-align: left;
}
.PopUp .div_data .btn_save{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.PopUp .div_data .btn_save .Comp_Btn_AddData{
    margin: 0;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 1px solid #606B7A;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #606B7A;
    height: fit-content;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .register_data{
    background-color: #7c9f06;
    width: auto;
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 16px;
}
.PopUp .opt_select{
    cursor: pointer;
    width: auto;
    color: #ffffff;
    flex-grow: 1;
}
.PopUp .yes_update {
    background-color: #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .not_update {
    background-color: #e95656;
    padding: 6px 10px;
    border-radius: 8px;
}
/* end */