.div_inf_page_site .div_icon{
    width: 24px;
    display: flex;
}
.div_inf_page_site .bottom{
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.div_inf_page_site .top{
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}