.PopUp .Category{
    width: 600px;
    margin: 0;
}
.PopUp .Category .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .Category .div_input{
    width: auto;
}
.PopUp .Category .div_btn{
    width: 100%;
    border-radius: 6px;
}
.PopUp .Category .btn_save{
    width: 100%;
    padding: 8px 10px;
    background-color: #005FA1;
    border: none;
    cursor: pointer;
    color: #ededed;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Inter';
    border-radius: 6px;
    margin-top: 20px;

    justify-content: center;
}
.PopUp .Category .btn_save:hover{
    background-color: #0079CD;
}
.PopUp .Category .file_category{
    width: auto;
    height: 26px;
    cursor: pointer;
}

@media only screen and (max-width: 570px) {
    .PopUp .Category {
        width: 90%;
    }
}