.Comp_Input{
    position: relative;
    margin-top: 8px;
    color: #424242;
}
.Comp_Input input{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.Comp_Input .name{
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0px 10px;
    background: #ffffff;
}