.div_show_data_access .Comp_Select .type_access{
    width: 230px;
}
.div_show_data_access .Comp_Input .inf_input {
    width: 90px;
    text-align: center;
}
.div_show_data_access table{
    padding: 0px 20px 20px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}
.div_show_data_access .Comp_Input .register_date{
    width: 134px;
    text-align: center;
}
.div_show_data_access .Comp_Input .qtd_item{
    width: 42px;
    text-align: center;
}
.div_show_data_access .Comp_Input .price{
    width: 80px;
    text-align: center;
}
.div_show_data_access .Comp_Select .type_status{
    min-width: 130px !important;
}
.div_show_data_access .Comp_Input .type_status_input{
    width: 80px;
    text-align: center;
}