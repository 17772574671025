.div_inf_page_settings .div_icon{
    position: relative;
    top: 10px;
}
.div_inf_page_settings .div_icon .icons{
    width: 36px;
    height: auto;
}
.div_inf_page_settings .company_name{
    width: 180px;
}
.div_inf_page_settings .whatsApp{
    width: 116px;
}
.div_inf_page_settings .div_color{
    width: auto;
    height: 36px;
    padding-left: 16px;
    
    border: 2px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.div_inf_page_settings .div_color .show_div_color{
    width: 30px;
    height: 100%;
    border-left: 1px solid #ebebeb;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.div_inf_page_settings .div_list_palette{
    width: calc(100% - 24px);
    height: 36px;
    padding: 10px;
    border: 2px solid #ebebeb;
    border-radius: 8px;
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.div_inf_page_settings .div_list_palette .name_title{
    position: absolute;
    top: -12px;
    background: #ffffff;
    padding: 0px 10px;
}
.div_inf_page_settings .div_list_palette .div_color{
    width: 100px;
}

.div_inf_page_settings .div_show_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.div_inf_page_settings .div_show_btn:nth-child(n + 2) {
    padding-top: 20px;
    border-top: 2px solid #ebebeb;
}