.input_search{    
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.input_search .icons_search{
    position: absolute;
    top: 11px;
    left: 8px;
    height: 20px;
    width: 20px;
    transition: 0.5s;
}
.input_search .search{
    background-color: #CDD1D6;
    border: 2px solid #CDD1D6;
    border-radius: 8px;
    padding: 10px 10px 10px 30px;
    width: 200px;
}
.input_search ::placeholder {
    color: #606875;
}