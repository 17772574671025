.div_show_data_access .Comp_Select .type_access{
    width: 230px;
}
.div_show_data_access .Comp_Input .inf_input {
    width: 90px;
    text-align: center;
}
.div_show_data_access table{
    padding: 0px 20px 20px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}
.div_show_data_access .Comp_Input .cpf{
    width: 110px;
    text-align: center;
}
.div_show_data_access .Comp_Input .cep{
    width: 80px;
    text-align: center;
}
.div_show_data_access .Comp_Input .state{
    width: 40px;
    text-align: center;
    text-transform: uppercase;
}
.div_show_data_access .Comp_Input .phone{
    width: 120px;
}
.div_show_data_access .Comp_Input .number{
    width: 80px;
    text-align: center;
}