.PopUp .PopUpColor{
    width: 300px;
    margin: 0 auto;
    color: #000000;
}
.PopUp .PopUpColor .InputColor{ 
    width: calc(100% - 60px);
    padding: 30px;
    overflow: hidden;
}
.PopUp .PopUpColor .InputColor .div_saturation{    
    width: 240px;
    height: 146px;
    position: relative;
    margin-bottom: 20px;
}
.PopUp .PopUpColor .InputColor .w-color-saturation{
    width: 240px !important;
    height: 146px !important;
    border-radius: 8px !important;
}
.PopUp .PopUpColor .InputColor .div_hue{
    position: relative;
    margin-bottom: 16px;
}
.PopUp .PopUpColor .InputColor .hue-picker{
    width: 240px !important;
}
.PopUp .PopUpColor .InputColor .hue-horizontal{
    border-radius: 10px !important;
}
.PopUp .PopUpColor .InputColor .div_input{    
    position: relative;
    margin-bottom: 10px;
}
.PopUp .PopUpColor .InputColor .color_select{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 202px;
}
.PopUp .PopUpColor .InputColor .color_hex{
    width: 100%;
    display: flex;
    align-items: center;
}
.PopUp .PopUpColor .InputColor .color_rgb{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.PopUp .PopUpColor .InputColor .color_rgb_div{
    height: 16px;   
    width: -webkit-fill-available;
    margin-top: 4px;
}
.PopUp .PopUpColor .InputColor .input_rgb{
    width: 28px;
    height: 30px;
    padding: 0px 6px;
    text-align: center;
}
.PopUp .PopUpColor .InputColor .svg_icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.PopUp .PopUpColor .InputColor .div_select_type{
    text-align: left;
    display: flex;
    gap: 20px;
}
.PopUp .PopUpColor .InputColor .type{
    font-weight: 600;
    font-size: 16px;
}
.PopUp .PopUpColor .InputColor .div_button{
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}
.PopUp .PopUpColor .InputColor .cancel{
    cursor: pointer;
    color: #E51D4D;
}
.PopUp .PopUpColor .InputColor .add_color{
    cursor: pointer;
    color: #FFFFFF;
    padding: 8px 16px;
    background: #E51D4D;
    border-radius: 8px;
}