.Page_Schedule{
    padding-bottom: 40px;
}
.Page_Schedule .div_show_calendar{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
}
.Page_Schedule .div_show_calendar .legend{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Schedule .div_show_calendar .legend .div_opt{
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}
.Page_Schedule .div_show_calendar .legend .div_all{
    border: 2px solid;
    padding: 6px 20px;
}
.Page_Schedule .div_show_calendar .legend .div_opt .div_eye{
    position: absolute;
    right: 0;
    top: -12px;
    background: #ffffff;
    padding: 2px;
    border-radius: 20px;
    border: 0.5px solid #2d3c53;
}
.Page_Schedule .div_show_calendar .legend .div_opt .div_eye .icons{
    width: 16px;
    height: auto;
}

.Page_Schedule .div_show_calendar .div_calendar{
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
}
.Page_Schedule .div_show_calendar .div_calendar .rbc-time-content{
    display: none;
}
.Page_Schedule .div_show_calendar .div_calendar .rbc-calendar{ 
    width: 100%;
}
.Page_Schedule .div_show_calendar .div_calendar .rbc-toolbar .rbc-toolbar-label{
    text-transform: capitalize;
}
.Page_Schedule .div_show_calendar .div_calendar .rbc-day-bg+ .Page_Schedule .div_show_calendar .div_calendar .rbc-day-bg,
.Page_Schedule .div_show_calendar .div_calendar .rbc-month-row{
    min-height: 140px;
    cursor: pointer;
}

.Page_Schedule .div_show_calendar .div_calendar .rbc-row{
    height: 26px;
    min-height: 26px;
}